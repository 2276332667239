import bootstrap from "bootstrap/dist/js/bootstrap"
import './awesome.css';
window.bootstrap = bootstrap;
import '@iconfu/svg-inject';
import $ from 'jquery';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { swiffyslider } from 'swiffy-slider'
window.swiffyslider = swiffyslider;
import "swiffy-slider/css"


global.$ = global.jQuery = $;
//window.Fancybox = Fancybox;

import './tools/consent';
import './tools/form-api'
import './tools/siteModal'
import './tools/stimulus'
import './tools/turbo';
import './tools/turbo-helper';

const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))

window.setMapInit = function()
{
    //Ersetze in allen Controllern den Value:

    document.querySelectorAll('[data-gmap-maploaded-value]').forEach(function(node){
        node.dataset.gmapMaploadedValue="1";
    });
}

