import { Controller } from '@hotwired/stimulus';
import sortable from "html5sortable/dist/html5sortable.es";

export default class extends Controller {


    static values = {
        target: String
    }



    connect() {

    }

    submit()
    {
        var element=document.querySelector(this.targetValue);

        if (!element.checkValidity())
        {
            element.reportValidity();
        }
        else
        {
            element.submit();
        }



    }


}