import { Controller } from '@hotwired/stimulus';
import { useIntersection } from "stimulus-use";

export default class extends Controller {


    static targets = [ "colorInput","textInput","selectColorBtn" ]

    connect() {



        this.changeText();

    }

    changeText(event)
    {

        this.selectColorBtnTarget.style.backgroundColor="";
        this.selectColorBtnTarget.style.backgroundColor=this.textInputTarget.value;

    }

    selectColor(event)
    {
        this.colorInputTarget.click();
    }

    pickColor(event)
    {
        this.selectColorBtnTarget.style.backgroundColor=this.colorInputTarget.value;
        this.textInputTarget.value=this.colorInputTarget.value;
    }

}