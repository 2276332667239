// src/controllers/permissions_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["noRestrictions", "listGroup"]

    connect() {
        this.toggleListGroupVisibility();
        this.noRestrictionsTarget.addEventListener('change', () => this.toggleListGroupVisibility());


    }

    toggleListGroupVisibility() {
        if (this.noRestrictionsTarget.checked) {
            console.log("checled");
            this.listGroupTarget.style.display = 'none'
        } else {
            console.log("not checked");
            this.listGroupTarget.style.display = 'block'
        }
    }
}
