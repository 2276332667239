import { Controller } from '@hotwired/stimulus';
import { useIntersection } from "stimulus-use";

export default class extends Controller {
    static targets = ["activeItem"];

    appeared = false;

    connect() {
        if (this.hasActiveItemTarget) {
            this.activeItemTarget.scrollIntoView({ behavior: "auto" });
        }
    }
}
