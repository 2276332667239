import { Controller } from '@hotwired/stimulus';
import sortable from "html5sortable/dist/html5sortable.es";
import  CryptoJS  from  "crypto-js";

export default class extends Controller {

    static targets = [ "list","fileinput", "uploadskeleton", "dropzone", "inner" ]
    static values = {
        project: String
    }


    uploadid=1;
    uploadrunning=false;
    uploadqueue=[];
    sortableinit=false;
    currentxhr=null;
    canceledjobs=[];


    connect() {



            this.getEditor();

    }


    initSortable()
    {



        let sortlist=sortable(this.listTarget,{ handle: ".sorthandle",
            placeholderClass: 'w-100 list-group-item bg-primary pt-5 pb-5'});

        if (!this.sortableinit) {
            sortlist[0].addEventListener('sortupdate', (event) => {
                this.updateSort();
                return true;
            });
            this.sortableinit=true;
        }
    }

    async updateSort()
    {
        console.log("update");

        let newsortlist=[];
        let list=this.listTarget.querySelectorAll(".list-group-item>div[data-frontfileselement-fileid-value]");
        list.forEach((element) => {
           newsortlist.push(element.dataset.frontfileselementFileidValue);
        });

        let formdata=new FormData();

        formdata.append("project",this.projectValue);
        formdata.append("action","updateSort");
        formdata.append("ajax",1);
        formdata.append("items",newsortlist);


        let response = await fetch('/admin/files', {
            method: 'POST', // or 'PUT'
            body: formdata,
            headers: {
                'Accept': 'application/json'

            }
        });
        let data = await response.json();
    }

    async getEditor()
    {
        let response = await fetch('/admin/files?action=getEditor&ajax=1&project=' + this.projectValue, {
            method: 'GET', // or 'PUT'
            headers: {
                'Accept': 'application/json'
            }
        });

        let data = await response.json();


        let e=document.createElement("div");
        this.element.innerHTML=data.editor;

        this.initSortable();

    }

    selectFile(event)
    {
        this.fileinputTarget.click();
    }

    drop(event)
    {

        event.preventDefault();
        event.stopPropagation();
        this.innerTarget.classList.remove("bg-info");
        this.innerTarget.classList.add("bg-light");


        this.executeUpload(event.dataTransfer.files);
    }

    dragin(event)
    {
        event.preventDefault();
        this.innerTarget.classList.add("bg-info");
        this.innerTarget.classList.remove("bg-light");
    }

    dragleave(event)
    {
        event.preventDefault();
        this.innerTarget.classList.remove("bg-info");
        this.innerTarget.classList.add("bg-light");
    }



    uploadFile(event)
    {

        this.executeUpload(this.fileinputTarget.files);
    }

    executeUpload(files)
    {

        Array.from(files).forEach(function(file) {
            let container=this.getUploadContainer(file.name,file.size);

            this.addToQueue(file,container);},this);
    }


    addToQueue(file,container)
    {
        let uploadid=container.dataset.uploadid;
        this.uploadqueue.push({"file":file,"container":container, "uploadid": uploadid });
        this.runqueue();
    }

    runqueue()
    {
        if (this.uploadrunning === false)
        {
            //Erstes Element holen:
            if (this.uploadqueue.length > 0) {
                let element = this.uploadqueue.shift();
                this.transferToApi(element.file, element.container);
            }
        }
    }

    abortTransfer(event)
    {

        event.target.setAttribute('disabled','disabled');
        let container=event.target.parentNode.parentNode.parentNode;
        let uploadid=(container.dataset.uploadid);

        if (this.currentxhr !== null)
        {
            if (this.currentxhr.uploadid == uploadid)
            {
                this.currentxhr.xhr.abort();
            }
        }

        this.canceledjobs.push(uploadid);

        container.remove();


    }

    async getFileFragment(fileid,container)
    {
        let response = await fetch('/admin/files?action=getFileFragment&fileid='+fileid+'&ajax=1&project=' + this.projectValue, {
            method: 'GET', // or 'PUT'
            headers: {
                'Accept': 'application/json'
            }
        });

        let data = await response.json();
        let e=document.createElement("div");
        container.innerHTML=data.fragment;
        this.initSortable();
    }

    calcMD5(file,container) {
        container.querySelector(".progress-bar").classList.add("bg-dark");
        container.querySelector(".progress-bar").classList.remove("bg-primary");

        this.getMD5(
            file,
            prog => {
                let percent=Math.round(prog*100);
                container.querySelector("progresstext").innerHTML="Prüfsumme berechnen... " + percent + "%";

                container.querySelector(".progress-bar").style.width=percent + "%";
            }
        ).then(


            res => this.beginTransfer(file,container,res)


        );
    }

    readChunked(file, chunkCallback, endCallback) {
        var fileSize   = file.size;
        var chunkSize  = 4 * 1024 * 1024; // 4MB
        var offset     = 0;

        var reader = new FileReader();
        reader.onload = function() {
            if (reader.error) {
                endCallback(reader.error || {});
                return;
            }
            offset += reader.result.length;

            chunkCallback(reader.result, offset, fileSize);
            if (offset >= fileSize) {
                endCallback(null);
                return;
            }
            readNext();
        };

        reader.onerror = function(err) {
            endCallback(err || {});
        };

        function readNext() {
            var fileSlice = file.slice(offset, offset + chunkSize);
            reader.readAsBinaryString(fileSlice);
        }
        readNext();
    }

    getMD5(blob, cbProgress) {
        return new Promise((resolve, reject) => {

            var md5 = CryptoJS.algo.MD5.create();
            this.readChunked(blob, (chunk, offs, total) => {
                md5.update(CryptoJS.enc.Latin1.parse(chunk));
                if (cbProgress) {
                    cbProgress(offs / total);
                }
            }, err => {
                if (err) {
                    reject(err);
                } else {
                    var hash = md5.finalize();
                    var hashHex = hash.toString(CryptoJS.enc.Hex);
                    resolve(hashHex);
                }
            });
        });
    }

    async beginTransfer(file,container,checksum)
    {


        let form=new FormData();

        form.append("ajax","1")
        form.append("filename",file.name);
        form.append("action","uploadViaChecksum")
        form.append("project",this.projectValue);
        form.append("checksum",checksum);

        //Prüfe ob Datei schon vorhanden:
        let response = await fetch('/admin/files', {
            method: 'POST', // or 'PUT'
            body: form,
            headers: {
                'Accept': 'application/json'
            }
        });

        let d = await response.json();



        if (d.file_exists)
        {
            this.getFileFragment(d.fileid,container);
            this.uploadrunning=false;
            this.runqueue();
            return ;
        }

        //Existiert der Auftrag noch?
        let found=false;
        this.canceledjobs.forEach((element) => {

            if (element == container.dataset.uploadid)
            {
                found=true;
            }


        });
        if (found)
        {


            this.uploadrunning=false;
            this.runqueue();
            return ;
        }
        else
        {

        }

        container.querySelector(".progress-bar").classList.remove("bg-dark");
        container.querySelector(".progress-bar").classList.add("bg-primary");
        container.querySelector("progresstext").innerHTML="Datei wird übertragen..."



        let xhr=new XMLHttpRequest();

        let uploadid=container.dataset.uploadid;
        this.currentxhr={ "uploadid": uploadid, "xhr": xhr }

        xhr.open("POST","/admin/files");
        xhr.upload.addEventListener("progress",({loaded, total}) =>{
            console.log(loaded,total);
            let percent=Math.round((100 / total) * loaded);
            container.querySelector(".progress-bar").style.width=percent + "%";
            container.querySelector("progresstext").innerHTML="Datei wird übertragen... " + percent + "%";
        } );

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                let status=xhr.status;
                this.uploadrunning=false;
                this.runqueue();
                this.currentxhr=null;
                container.querySelector(".progress-bar").classList.remove("progress-bar-animated");
                container.querySelector(".progress-bar").classList.remove("progress-bar-striped");
                if (status === 0 || (status >= 200 && status < 400)) {
                    let data=JSON.parse(xhr.response);

                    container.querySelector(".progress-bar").classList.add("bg-success");

                    this.getFileFragment(data.fileid,container);
                }
                else
                {
                    container.querySelector(".progress-bar").classList.add("bg-danger");
                    console.log("fail");
                }
            }
        }

        let data=new FormData();
        data.append("ajax","1")
        data.append("file",file);
        data.append("action","uploadFile")
        data.append("project",this.projectValue);
        xhr.send(data);
    }

    transferToApi(file,container)
    {
        this.uploadrunning=true;
        let checksum=this.calcMD5(file,container)



    }

    getUploadContainer(filename,size)
    {
        let uploadid=this.uploadid +1;
        this.uploadid=uploadid;
        this.listTarget.insertAdjacentHTML("beforeend",this.uploadskeletonTarget.innerHTML);
        let newElement=this.listTarget.lastElementChild;
        newElement.querySelector("filename").innerHTML=filename;
        newElement.querySelector("filesize").innerHTML=(Math.round((size * 10 / 1024 / 1024) ) / 10) ;
        newElement.removeAttribute("data-frontfiles-target");
        newElement.dataset.uploadstate="prepare";
        //newElement.querySelector(".progress-bar").style.width="10%";
        newElement.dataset.uploadid=uploadid;
        return newElement;

    }




}