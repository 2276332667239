import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["button", "loader", "text"];

    connect() {
        this.loaderTarget.style.display = "none";
    }

    submit(event) {
        this.buttonTarget.disabled = true;
        this.element.querySelectorAll("input, select, textarea, button").forEach(element => {
            element.disabled = true;
        });
        this.textTarget.style.display = "none";
        this.loaderTarget.style.display = "inline-block";
    }
}
