// assets/controllers/link_table_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["row"];

    connect() {
        this.element.querySelectorAll("tr[href]").forEach(row => {
            row.style.cursor = "pointer";
        });
        this.element.addEventListener("click", this.handleClick.bind(this));
    }

    handleClick(event) {
        let row = event.target.closest("tr");
        if (row && row.hasAttribute("href")) {
            let href = row.getAttribute("href");
            if (event.ctrlKey || event.metaKey) {
                window.open(href, "_blank");
            } else {
                window.location.href = href;
            }
        }
    }
}
