import $ from "jquery";
import bootstrap from "bootstrap/dist/js/bootstrap"

console.log("plugin openmodal");



$(document).delegate('.open-modal-btn', "click", async function (event) {

    event.preventDefault();
    createModalElement();



   $("#siteModal-dialog").attr("class",$(this).data("size") + " modal-dialog-centered modal-dialog");

   var mode=$(this).data("mode");
    $('#siteModalTitle').html($(this).data("targetcaption"));
    const siteModal = new bootstrap.Modal('#siteModal', {
        backdrop: (($(this).data("backdrop") ? "static" : true))
    });



    if (!$('#siteModalTitle').data("init")) {
        $('#siteModalTitle').data("init",1);
        document.getElementById("siteModal").addEventListener('hidden.bs.modal', event => {
            console.log("modal clsoe");
            $('#siteModalBody').html("");
        });
    }

    var modalElement=$('#siteModal');


   if (mode=="extern")
   {

       $('#siteModalBody').html("<iframe width='100%' height='600'  frameborder='0' src='"+$(this).attr("href")+"'></iframe>");
       $('#siteModalTitle').html("<span>"+$(this).data("targetcaption")+" </span><div class='fs-8 overflow-hidden text-nowrap' style='max-width: 80%;'> von <a class='text-decoration-none text-muted  ' target='_blank' href='"+$(this).attr("href")+"'>"+$(this).attr("href")+"</a></div>")
       siteModal.show();
   }
   else
   {

       var formdata=new FormData;
       formdata.append("modalCall",1)

       let response = await fetch($(this).attr("href"), {
           method: 'POST', // or 'PUT'
           body: formdata,
           headers: {
               'Accept': 'text/html'
           }
       });

       $('#siteModalBody').html("");
       const html =await response.text();
       $('#siteModalBody').html(html);
       siteModal.show();

   }





});

function createModalElement()
{

    if ($('#siteModal').length < 1) {
        // language=HTML
        var html = "<div class=\"modal fade\" id=\"siteModal\" tabindex=\"-1\">\n    <div id=\"siteModal-dialog\" class=\" modal-dialog-scrollable modal-dialog modal-dialog-centered\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header\">\n                <h5 class=\"modal-title\" id=\"siteModalTitle\"></h5>\n                <button type=\"button\" class=\"btn-close\" data-bs-dismiss=\"modal\" aria-label=\"Close\"></button>\n            </div>\n            <div class=\"modal-body\" id=\"siteModalBody\">\n                \n            </div>\n        </div>\n    </div>\n</div>";
        $("body").append(html);
    }
}