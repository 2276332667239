import { Controller } from '@hotwired/stimulus';
import { useIntersection } from "stimulus-use";
import $ from "jquery";

export default class extends Controller {


    static targets = [ "textarea" ]

    editorId=null;


    connect() {


        this.editorId=this.textareaTarget.id;

        this.initTinyMce();



    }

    async initTinyMce(elementId) {

        let fontFamiliesFormat = [];
        let formats={};
        let fontFamilies={};


        if (this.textareaTarget.dataset.contentFontfamilies) {
            let response = await fetch(this.textareaTarget.dataset.contentFormats);
            let fontFamiliesResponse = await fetch(this.textareaTarget.dataset.contentFontfamilies);
            formats = JSON.parse(await response.text());
            fontFamilies = JSON.parse(await fontFamiliesResponse.text()).fontFamilies;
            fontFamilies.forEach((family) => {
                fontFamiliesFormat.push("" + family.name + "=" + family.family + "");
            });
        }

        if (this.textareaTarget.dataset.smart) {
            tinymce.init({
                selector: "#" + this.editorId,
                height: 250,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'help', 'wordcount'
                ],
                toolbar: 'undo redo | bold italic | bullist numlist  ' +
                    'removeformat ',
                menubar: "",
                language: 'de',
                menu: {}
            });

        }
        else
        {
            tinymce.init({
                selector: "#" + this.editorId,
                height: 500,
                content_css: this.textareaTarget.dataset.contentCss,
                style_formats: formats.formats,
                font_family_formats: fontFamiliesFormat.join(";"),
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'help', 'wordcount'
                ],
                toolbar: 'undo redo | blocks | fontfamily | ' +
                    'bold italic backcolor | styles | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                menubar: "edit view insert format  table",

                language: 'de',
                menu: {
                    file: {
                        title: 'File',
                        items: 'newdocument restoredraft | preview | export print | deleteallconversations'
                    },
                    edit: {title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace'},
                    view: {
                        title: 'View',
                        items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments'
                    },
                    insert: {title: 'Insert', items: 'link inserttable | charmap hr'},
                    format: {
                        title: 'Format',
                        items: 'bold italic superscript subscript codeformat | fontfamily | styles blocks align  | removeformat'
                    },
                    table: {
                        title: 'Table',
                        items: 'inserttable | cell row column | advtablesort | tableprops deletetable'
                    },
                    help: {title: 'Help', items: 'help'}
                }
            });
        }




        // Prevent Bootstrap dialog from blocking focusin
        document.addEventListener('focusin', (e) => {
            if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
                e.stopImmediatePropagation();
            }});
    }

    disconnect() {
        super.disconnect();
        console.log("disconnect");
        tinymce.EditorManager.execCommand('mceRemoveEditor',true,  this.editorId);
    }


}