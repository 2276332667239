import cookie from 'cookiejs';
import bootstrap from "bootstrap/dist/js/bootstrap"
import $ from "jquery";

const consentModal = document.getElementById('consentModal');

function apiStandardCalls(json)
{
    if (json.message)
    {
        alert(message);
        return false;
    }
    if (json.reload)
    {
        document.location.reload();
        return false;
    }
    if (json.location)
    {
        document.location=json.location;
        return false;
    }
    if (!json.state)
    {
        return false;
    }
    return true;
}

$(document).ready(function() {

    $(document).on( "show.bs.modal","#consentModal",async event => {
        console.log("show consent modal");

        let response  = await fetch("/api/privacy?action=getModal");
        if (response.ok) {
            let json = await response.json();
            if (apiStandardCalls(json))
            {
                let target=document.getElementById('consent-modal-content');
                target.innerHTML=json.modalContent;
            }

        } else {
            alert("HTTP-Error: " + response.status);
        }


        //fetch()
        //TODO: Lade Cookie-Modal nach
        //Infrastruktur hierfür schaffen!
    });
    $(document).on( "hidden.bs.modal","#consentModal",async event => {
   // consentModal.addEventListener('hidden.bs.modal', event => {

        cookie.set('consentToolState',1,365);
        document.location.reload();
    });


    $(document).delegate('#consentDismissAllBtn', "click", function (event) {
        $('.consent-swtich').prop("checked",false).trigger("change");
        cookie.set('consentToolState',1,365);
        document.location.reload();

    });

    $(document).delegate('#consentAllowAllBtn', "click", function (event) {
        $('.consent-swtich').prop("checked",true).trigger("change");
        cookie.set('consentToolState',1,365);
        document.location.reload();

    });

    $(document).delegate('.enableConsentBtn', "click", function (event) {
        event.preventDefault();
        cookie.set('consent_' + $(this).data("consent-service"),1,365);
        document.location.reload();
    });



    $(document).delegate('.consent-swtich', "change", function (event) {
        event.preventDefault();
        if ($(this).prop('checked'))
        {
            cookie.set('consent_' + $(this).attr("name"),1,365);
        }
        else
        {

            cookie.remove('consent_' + $(this).attr("name"));
            cookie.set('consent_' + $(this).attr("name"),0,365);
        }

        console.log("consent-item-switches");
        console.log($(this).prop('checked'));

    });

    //Wenn noch nie Consent, dann Tool aufrufen:

    if (!cookie.get('consentToolState'))
    {

        const consentModal=new bootstrap.Modal('#consentModal');
        consentModal.show();


    }


});