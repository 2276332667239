import { Controller } from '@hotwired/stimulus';
import bootstrap from "bootstrap/dist/js/bootstrap"

export default class extends Controller {

    static targets = [ "input","modalbody","variant","list" ]
    static values = {
        fonts: Number
    }


    selectedFamily=null;
    fontModal=null;

    connect() {



    }

    async install(event)
    {


        this.selectedFamily=event.target.dataset.familyid;
        let response = await fetch('/admin/fonts?ajax=1&action=getFamily&familyid=' + event.target.dataset.familyid, {
            method: 'GET',
        });
        let responseObject = await response.json();


        this.modalbodyTarget.innerHTML=responseObject.modalContent;
        this.fontModal=new bootstrap.Modal("#fontModal");
        this.fontModal.show();


    }

    async installFont(event) {



        let variants=[];
        this.variantTargets.forEach(element => {
            if (element.checked)
            {
                variants.push(element.value);
            }

        });



        let formData=new FormData();

        formData.append("variants",variants);
        formData.append("familyid",this.selectedFamily);
        formData.append("action","install");
        formData.append("ajax","1");


        this.modalbodyTarget.innerHTML="Schrift wird geladen, installiert und eingebunden...<div class=\"progress mt-5 mb-5\">\n" +
            "  <div class=\"progress-bar progress-bar-striped progress-bar-animated\" role=\"progressbar\" aria-label=\"Animated striped example\" style=\"width: 100%\">" +
            "</div>\n" +
            "</div><i class='text-muted'>Dies kann bis zu 60 Sekunden dauern. Für Ihre Webseite werden neue Stylesheets (CSS-Dateien) erzeugt, damit sie die neue Schrift sofort nutzen können.</i>";


        let response = await fetch('/admin/fonts', {
            method: 'POST',
            body: formData
        });
        let responseObject = await response.json();
        this.fontModal.hide();


        this.reloadFontList();

    }

    async reloadFontList()
    {
        let response = await fetch('/admin/fonts?ajax=1&action=getFontList');
        let responseObject = await response.json();

        this.listTarget.innerHTML=responseObject.html;
    }

    async remove(event)
    {
        let button=event.target;

        if (event.target.tagName != "BUTTON")
        {
             button=button.parentNode;
        }


        let fontid=(button.dataset.fontid);
        let trline=button.parentNode.parentNode;


        let formData=new FormData();
        formData.append("id",fontid);
        formData.append("action","removeFont");
        formData.append("ajax","1");

        let response = await fetch('/admin/fonts', {
            method: 'POST',
            body: formData
        });
        let responseObject = await response.json();

        if (responseObject.state==1)
        {
            this.reloadFontList();
        }
    }

}