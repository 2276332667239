import $ from "jquery";
global.setBtnState=function (target, state) {
    if (state) {
        target
            .html(target.data("original"))
            .prop('disabled',false);
    } else {
        target
            .data("original", target.html())
            .html('<i class="fa fa-spinner awesome-spin"></i>')
            .prop("disabled",true)
        ;
        convertIcons($(target).find("i"));
    }
}

function showFormFeedback(element,show=true,type="warning",text=null)
{

    if (show)
    {
        console.log(text);

        $(element).hide().html('   <div class="alert alert-'+type+' mt-3 mb-3"><div class="d-flex">\n' +
            '                        <div>\n' +
            '                            <i class="fa fa-3x float-start me-3 fa-check-circle"></i>\n' +
            '                        </div>\n' +
            '                        <div>\n' +
            '                            <p>'+text+'</p>\n' +
            '                        </div>\n' +
            '                    </div></div>').slideDown("slow");
    }
    else
    {
        $(element).hide();
    }


}

$(document).delegate('.form-api', "submit", function (event) {


    event.preventDefault();
    var url=null;
    showFormFeedback($(this).find('.form-feedback'),false)

    setBtnState($(this).find("button[type=submit]"),false);
    url=$(this).data("endpoint");
    var fileFormData=new FormData();

    $(this).find("input[type=file]").each(function () {
        var files = $(this).get(0).files;
        fileFormData.append($(this).attr("name"), files[0]);
    });

    fileFormData.append("action",$(this).attr("action"));
    fileFormData.append("ajax",1);

    var ths=$(this);

    var form=$(this).serializeArray();




    form.forEach(function(value,index){
        let name="form[" + value.name + "]";
        try {
            var element = $(ths).find("input[data-ace=1][name=" + value.name + "]");

            if (element.length > 0) {
                var editor = ace.edit(element.data("ace-source"));
                value.value = editor.getValue();

            }
        }catch (e) {
            
        }

        name=name.replace("[]]","][]");
        fileFormData.append(name,value.value);
    })
    {

    }




    $.ajax({
        type: "POST",
        url: url,
        context: $(this),

        accepts:  "text/json",
        data: fileFormData,
        processData: false,
        dataType: 'json',
        contentType: false,
        error: function()
        {
            showFormFeedback($(this).find('.form-feedback'),"true","danger",  "Serverantwort konnte nicht verstanden werden.");
            setBtnState($(this).find("button[type=submit]"),true);
        },


    }).done(function (data) {

        if (data && typeof data === 'object') {
            if (data.location) {
                document.location.href = data.location;
            }
            if (data.reload) {
                document.location.reload();
            }

            console.log(data.message);
            if ((data.state !== true) && (data.message === "")) {
                data.message = "Vorgang fehlgeschlagen.";
            }


            if ((data.message !== null) && (data.message !== undefined))
                showFormFeedback($(this).find('.form-feedback'),true,(data.state === true ? "success" : "danger"),  data.message);
        }
        else
        {
            console.log(data);
            showFormFeedback($(this).find('.form-feedback'),"true","danger",  "Serverantwort konnte nicht verstanden werden.");
        }

        setBtnState($(this).find("button[type=submit]"),true);
    })
});

global.convertIcons= function(elements)
{
    $(elements).each(function() {
        var icon = null;
        var typ = null;
        var additionalClasses=[];

        $(this).get(0).classList.forEach(function (className) {

            var fonttags = ["fa", "fal", "fab", "fas"];
            var typemap = {"fa": "regular", "fal": "light", "fab": "brands", "fas": "solid","awesomePath":"awesomePath"};
            var ignore = ["fa-fw"];


            var matches;
            //Finde fa-Klassen:
            if (ignore.indexOf(className) == -1) {
                if (fonttags.indexOf(className) != -1) {
                    typ = typemap[className];
                } else {
                    if (matches = className.match(/fa-/)) {
                        icon = className.replace(/^fa-/, '');
                    }
                    else if (matches = className.match(/([a-z0-9]{3,10})\/([a-z0-9-]{3,25})/))
                    {
                        typ=matches[1];
                        icon=matches[2];
                    }
                    else
                    {
                        additionalClasses.push(className);
                    }
                }
            }




        });

        $(this).replaceWith('<img class="awesome-icon awesome-inject-icon '+additionalClasses.join(" ")  +' "  src="/plugins/awesome/svgs/' + typ + '/' + icon + '.svg" >');

        injectIcons($(this));
    });
}

global.injectIcons= function(target=null)
{



    $('img.awesome-inject-icon').each(function(){
        SVGInject($(this));
    })
}