import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['form', 'feedback','password','passwordRepeat'];
    static values = { endpoint: String }

    connect() {
        console.log('User registration controller connected');
    }

    // Event-Handler für das Absenden des Formulars
    submit(event) {
        event.preventDefault(); // Verhindert das Standard-Verhalten des Formulars
        const url = this.endpointValue;
        const formData = new FormData(this.formTarget);

        if (!this.check_password())
        {
            return;
        }

        const jsonData = Object.fromEntries(formData.entries());

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonData)
        })
            .then(response => response.json())
            .then(data => {
                if (data.state === false) {
                    this.showFeedback('warning',data.message); // Zeigt Fehlermeldung, falls vorhanden
                } else {
                    this.handleSuccess(data); // Weiterverarbeitung bei Erfolg
                }
            })
            .catch(error => {
                console.error('Error:', error);
                this.showFeedback('warning','Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut.');
            });
    }

    check_password()
    {
        if (this.passwordTarget.value != this.passwordRepeatTarget.value) {

            this.showFeedback('warning', 'Die Passwörter sind nicht identisch.');
            return false;
        }
        this.showFeedback();
        return true;
    }

    // Zeigt Fehlermeldungen oder andere Benachrichtigungen
    showFeedback(type=null,message=null) {
        if (message == null)
        {
            this.feedbackTarget.classList.add("d-none");
        }
        this.feedbackTarget.classList.remove("d-none");
        this.feedbackTarget.textContent = message;
        this.feedbackTarget.classList.add('alert', 'alert-' + type);
    }

    // Verarbeitet erfolgreiche Anmeldung
    handleSuccess(data) {
        this.element.innerHTML='<div class="alert alert-success"><p><strong>Das Benutzerkonto wurde angelegt.</strong></p>Eine E-Mail mit dem Aktivierungslink wurde verschickt.</div>';

        window.scrollTo({
            top: this.element.getBoundingClientRect().top + window.pageYOffset,
            behavior: 'smooth'
        });
    }
}
