import { Controller } from '@hotwired/stimulus';
import Offcanvas  from 'bootstrap/js/dist/offcanvas';

export default class extends Controller {
    static targets = ["content", "source", "offcanvas","sourceContent"]

    connect() {
        this.offcanvas = Offcanvas.getOrCreateInstance(this.offcanvasTarget);
        this.initializeOffcanvasEvents();
    }

    initializeOffcanvasEvents() {


        this.offcanvas._element.addEventListener('show.bs.offcanvas', () => {
            console.log("move front");
            this.moveContentToOffcanvas();
        });

        this.offcanvas._element.addEventListener('hidden.bs.offcanvas', () => {
            console.log("move back");
            this.moveContentBack();

        });
    }

    toggle() {
        if (this.offcanvas._element.classList.contains('show')) {
            this.offcanvas.hide();
        } else {
            this.offcanvas.show();
        }
    }

    show() {
        if (!this.offcanvas._element.classList.contains('show')) {
            this.offcanvas.show();
        }
    }

    hide() {
        if (this.offcanvas._element.classList.contains('show')) {
            this.offcanvas.hide();
        }
    }

    moveContentToOffcanvas() {

        this.contentTarget.appendChild(this.sourceContentTarget);
    }

    moveContentBack() {
        this.sourceTarget.appendChild(this.sourceContentTarget);
    }

    disconnect() {
        // Entferne Event-Listener, um potenzielle Speicherlecks zu vermeiden.
        this.offcanvas._element.removeEventListener('show.bs.offcanvas', this.moveContentToOffcanvas);
        this.offcanvas._element.removeEventListener('hidden.bs.offcanvas', this.moveContentBack);
    }
}
