import { Controller } from '@hotwired/stimulus';
import data from "bootstrap/js/src/dom/data";
import Modal  from 'bootstrap/js/dist/modal';

export default class extends Controller {
    static targets = ['form', 'result','password','passwordRepeat','submitbtn','sendverifybtn'];
    static values = { endpoint: String }

    connect() {
        console.log('User login controller connected');
    }

    sendverification(event)
    {
        event.preventDefault();
        //Button der das Ereignis auslöst deaktivieren!
        event.target.disabled = true;
        const email = event.target.dataset.emailaddress;
        const url = this.endpointValue;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                action: "sendverification",
                email:email
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.state === false) {
                    this.showFeedback('warning',data.message); // Zeigt Fehlermeldung, falls vorhanden
                } else {
                    this.showFeedback('success',data.message); // Zeigt Fehlermeldung, falls vorhanden
                }
            })
            .catch(error => {
                console.error('Error:', error);
                this.showFeedback('warning','Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut.');
            });
    }

    // Event-Handler für das Absenden des Formulars
    login(event) {
        event.preventDefault(); // Verhindert das Standard-Verhalten des Formulars

        this.submitbtnTarget.querySelector('.spinner').classList.remove('d-none');
        this.submitbtnTarget.setAttribute("disabled", "disabled");

        const url = this.endpointValue;

        const formData = new FormData(this.formTarget);
        const jsonData = Object.fromEntries(formData.entries());

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonData)
        })
            .then(response => response.json())
            .then(data => {
                this.submitbtnTarget.querySelector('.spinner').classList.add('d-none');
                this.submitbtnTarget.removeAttribute("disabled");
                if (data.state === false) {
                    this.showFeedback('warning',data.message); // Zeigt Fehlermeldung, falls vorhanden
                } else {
                    this.handleSuccess(data); // Weiterverarbeitung bei Erfolg
                }
            })
            .catch(error => {
                this.submitbtnTarget.querySelector('.spinner').classList.add('d-none');
                this.submitbtnTarget.removeAttribute("disabled");
                console.error('Error:', error);
                this.showFeedback('warning','Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut.');
            });
    }



    // Zeigt Fehlermeldungen oder andere Benachrichtigungen
    showFeedback(type=null,message=null) {
        if (message == null)
        {
            this.resultTarget.classList.add("d-none");
        }
        this.resultTarget.classList.remove("d-none");
        this.resultTarget.innerHTML = message;
        this.resultTarget.classList.add('alert', 'alert-' + type);
    }

    // Verarbeitet erfolgreiche Anmeldung
    handleSuccess(data) {

        if (this.element.classList.contains('modal')) {
            var modal= Modal.getInstance(this.element);

            modal.hide();
        }

        if (data.reload)
        {
            document.location.reload();
        }
        if (data.location)
        {
            window.location.href = data.location;
        }

    }
}
