import { Controller } from '@hotwired/stimulus';



export default class extends Controller {

    static targets = [ "savebtn","fileNameInput","fileDescriptionInput" ]


    static values = {
        project: String,
        fileid: String
    }

    connect() {


    }

    showSaveBtn() {
        this.savebtnTarget.classList.remove("d-none");
    }



    async remove()
    {
        let fileid=this.fileidValue;
        let response = await fetch('/admin/files?action=removeFile&ajax=1&fileid='+fileid+'&project=' + this.projectValue, {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        });

        let data = await response.json();
        this.element.parentNode.remove();
    }

    async update(event)
    {
        event.preventDefault();
        event.stopPropagation();
        console.log("call update");
        console.log(event);
        let form=new FormData;
        form.append("filename", this.fileNameInputTarget.value);
        form.append("description", this.fileDescriptionInputTarget.value);
        form.append("project",this.projectValue);
        form.append("fileid",this.fileidValue);

        let response = await fetch('/admin/files?action=updateElement&ajax=1&project=' + this.projectValue, {
            method: 'POST',
            body: form,
            headers: {
                'Accept': 'application/json'
            }
        });

        let data = await response.json();

        if (!data.state)
        {
            alert(data.message);
        }

        this.savebtnTarget.classList.add("d-none");
    }
}
