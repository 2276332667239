// assets/controllers/amfe_controller.js
import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
    static targets = ["rackContainer", "temperature", "vds", "remotehead","result","error","form","contactFormModal","contactForm","contactFormResult","shareModal", "shareInput", "shareIcon", "sharedIcon","shareButton","loader"];
    static values = { endpoint: String };

    lastResult=null;
    sessionIdentifier=null;
    lastRequestIdentifier=null;
    documentTitle=null;

    connect() {
        this.init();
        this.documentTitle=document.title;

        const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
        const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
    }
    
    init() {

        this.getSessionIdentifier();

        const urlParams = new URLSearchParams(window.location.search);
        const identifier = urlParams.has("share") ? urlParams.get("share") : null;

        let showResult=urlParams.has("showResult") ? 0 : 1;


        if (identifier)
        {


            //Load requestData from Server:
            this.loadRequest(identifier,showResult);

        }
        else
        {
            this.createInitialRack();
            this.loaderTarget.classList.add("d-none");
            this.formTarget.classList.remove("d-none");
        }


    }

    createInitialRack() {
        if (this.rackContainerTarget.children.length === 0) {
            this.addRack();
        }
    }

    submitContactForm(event)
    {


        event.preventDefault();
        const data = new FormData(this.contactFormTarget);
        let jsonData = Object.fromEntries(data.entries());
        //console.log(jsonData);
        //jsonData.configuratorData = this.lastResult;
        jsonData.userSessionIdentifier=this.sessionIdentifier;
        jsonData.requestIdentifier=this.lastRequestIdentifier ?? null;


// Disable all buttons within the contact form
        this.contactFormTarget.querySelectorAll("button").forEach(button => {
            button.disabled = true;
        });
        

        fetch(this.endpointValue + "SubmitRequest", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(jsonData)
        })
            .then(response => response.json().then(result => ({status: response.status, body: result})))
            .then(({status, body}) => {
                if (status === 200) {
                    console.log("Formular erfolgreich gesendet:", body);


                    this.contactFormTarget.classList.add("d-none");

                    this.contactFormResultTarget.innerHTML = body.feedbackHtml;

                    this.contactFormResultTarget.classList.remove("d-none");

                    this.contactFormTarget.querySelectorAll("button").forEach(button => {
                        button.disabled = false;
                    });

                } else {
                    this.contactFormTarget.querySelectorAll("button").forEach(button => {
                        button.disabled = false;
                    });
                    console.error("Fehler beim Senden des Formulars:", body.message || "Ein unbekannter Fehler ist aufgetreten.");
                }
            })
            .catch(error => {
                console.error("Fehler:", error);
            });
    }
    
    showContactForm()
    {



        this.contactFormTarget.classList.remove("d-none");
        this.contactFormResultTarget.classList.add("d-none");

        const modal = new bootstrap.Modal(this.contactFormModalTarget);
        modal.show();
    }

    share()
    {
        this.shareIconTarget.classList.remove("d-none");
        this.sharedIconTarget.classList.add("d-none");
        this.shareButtonTarget.classList.remove("btn-outline-success");
        this.shareButtonTarget.classList.add("btn-outline-primary");


        const modal = new bootstrap.Modal(this.shareModalTarget);
        modal.show();
    }

    copyToClipboard(event) {

        const inputElement = this.shareInputTarget;

        console.log(inputElement.value);
        if (inputElement) {
            try {
                navigator.clipboard.writeText(inputElement.value)

                this.shareIconTarget.classList.add("d-none");
                this.sharedIconTarget.classList.remove("d-none");
                this.shareButtonTarget.classList.add("btn-outline-success");
                this.shareButtonTarget.classList.remove("btn-outline-primary");
            }
            catch (e) {
                console.error(e);
            }
        }
    }

    addRack(data=null) {
        const index = this.rackContainerTarget.children.length;

        const name=index +1;




        const newRack = document.createElement("div");
        newRack.classList.add("rack-item",  "mb-3", "col-12", "col-md-6", "col-lg-4", "col-xl-3");


        newRack.innerHTML = `
<div class="card">
<div class="card-header bg-primary text-light">
  <div class="d-flex justify-content-between align-items-center">

<div>
<span class="text-light">Schrank ${name}</span></div>
      
               <div> 
               <div class="dropdown">
  <button class="btn btn-transparent text-light btn-sm" type="button"  data-bs-toggle="dropdown" aria-expanded="false">
    <i class="fa fa-trash"></i>
  </button>
  <ul class="dropdown-menu">
    <li><button type="button" class="dropdown-item text-danger" data-action="amfe#removeRack">Schrank entfernen?</button></li>
   
  </ul>
</div>


               </div>
                </div>
</div>
<div class="card-body">

<div class="d-flex flex-column gap-2">
                
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" name="racks[${index}][name]" value="${data?.name ?? ''}">
                    <label for="racks[${index}][name]">Name (optional)</label>
                </div>
                
                <div class="form-floating mb-3">
                    <input type="number" min="1" max="50" class="form-control" name="racks[${index}][quantity]" value="${data?.quantity ?? 1}" placeholder="Anzahl">
                    <label for="racks[${index}][quantity]">Anzahl</label>
                </div>
    
                
                  <div class="input-group mb-3">
                <div class="form-floating ">
                
                        <input type="number" min="200" max="20000" class="form-control" required value="${data?.dimensions?.width ?? ''}" name="racks[${index}][width]" placeholder="Breite">
                        <label for="racks[${index}][x]">Breite</label>
                    </div>
                     <span class="input-group-text">mm</span>
                </div>
                
                  <div class="input-group mb-3">
                <div class="form-floating ">
                        <input type="number" min="200" max="20000" class="form-control" value="${data?.dimensions?.depth ?? ''}" name="racks[${index}][depth]" required name="racks[${index}][depth]" placeholder="Tiefe">
                        <label for="racks[${index}][z]">Tiefe</label>
                    </div>
                     <span class="input-group-text">mm</span>
                </div>
                
                  <div class="input-group mb-3">
                <div class="form-floating ">
                        <input type="number" min="200" max="20000" class="form-control" value="${data?.dimensions?.height ?? ''}" name="racks[${index}][height]" required name="racks[${index}][height]" placeholder="Höhe">
                        <label for="racks[${index}][y]">Höhe</label>
                    </div>
                     <span class="input-group-text">mm</span>
                </div>
</div>
</div>
            </div>`;
        this.rackContainerTarget.appendChild(newRack);
        convertIcons(this.rackContainerTarget.querySelectorAll("i"));


// Set focus to the first input in the newRack
        if (!data) {
            const firstInput = newRack.querySelector("input");
            if (firstInput) {
                firstInput.focus();
            }
        }
    }

    print(event) {
        window.print();
    }

    removeRack(event) {
        event.target.closest(".rack-item").remove();
    }

    showForm()
    {
        console.log("show form");
        this.formTarget.classList.remove("d-none");
        this.errorTarget.classList.add("d-none");
        this.resultTarget.classList.add("d-none");



        document.querySelectorAll(".display-configurator-start").forEach(element => {
            element.classList.remove("d-none");
        });
        document.querySelectorAll(".display-configurator-result").forEach(element => {
            element.classList.add("d-none");
        });



        window.scrollTo(0, 50);
    }


    getSessionIdentifier()
    {
        fetch(this.endpointValue + "getSession", {
            method: "POST",
            headers: { "Content-Type": "application/json" }
        })
            .then(response => response.json().then(result => ({ status: response.status, body: result })))
            .then(({ status, body }) => {
                if (status === 200) {

                    this.sessionIdentifier=body.sessionIdentifier;


                }}
            );
    }

    showResult(body)
    {
        if (body.resultHtml) {




            {



                this.setFormState(this.formTarget, true);
                this.resultTarget.classList.add("d-none");
                this.formTarget.classList.add("d-none");



                document.title = "AMFE Konfiguration vom " +
                    new Date().toLocaleDateString("de-DE", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit"
                    });


            this.lastResult = body;
            this.resultTarget.innerHTML = body.resultHtml;
            this.resultTarget.classList.remove("d-none");

            if (body.result.identifier) {
                this.lastRequestIdentifier = body.result.identifier;
            }



            const currentUrl = new URL(window.location.href);

            if (this.lastRequestIdentifier) {
                currentUrl.searchParams.set("share", this.lastRequestIdentifier); // Update or add the "share" param
                window.history.replaceState(null, "", currentUrl.toString()); // Update URL without reloading the page
            }

            document.querySelectorAll(".display-configurator-start").forEach(element => {
                element.classList.add("d-none");
            });
            document.querySelectorAll(".display-configurator-result").forEach(element => {
                element.classList.remove("d-none");
            });

            window.scrollTo(0, 50);
            }
        }
    }

    submit(event) {

        this.errorTarget.classList.add("d-none");
        if (!this.formTarget.checkValidity()) {
            this.errorTarget.innerHTML = "Bitte prüfen sie die hervorgehobenen Felder.";
            this.errorTarget.classList.remove("d-none");
            this.formTarget.reportValidity();

            return;
        }

        this.setFormState(this.formTarget,false);


        const data = {
            id: 0,
            temperature: this.temperatureTarget.value,
            vds: this.vdsTarget.checked ? 1 : 0,
            remote: this.remoteheadTarget.checked ? 1 : 0,
            userSessionIdentifier: this.sessionIdentifier,
            configuratorWebseite: window.location.href,
            racks: Array.from(this.rackContainerTarget.children).map((rack, index) => ({
                name: rack.querySelector(`[name="racks[${index}][name]"]`).value,
                quantity: parseInt(rack.querySelector(`[name="racks[${index}][quantity]"]`).value, 10),
                dimensions: {
                    width: parseInt(rack.querySelector(`[name="racks[${index}][width]"]`).value, 10),
                    height: parseInt(rack.querySelector(`[name="racks[${index}][height]"]`).value, 10),
                    depth: parseInt(rack.querySelector(`[name="racks[${index}][depth]"]`).value, 10)
                }
            }))
        };

        console.log("Gesendete Daten:", JSON.stringify(data, null, 2));

        fetch(this.endpointValue, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        })
            .then(response => response.json().then(result => ({ status: response.status, body: result })))
            .then(({ status, body }) => {
                if (status === 200) {
                    console.log("Antwort:", body);
                    this.showResult(body);
                } else {
                    this.setFormState(this.formTarget,true);
                    this.errorTarget.innerHTML = body.message || "Ein unbekannter Fehler ist aufgetreten.";
                    this.errorTarget.classList.remove("d-none");
                    this.formTarget.classList.remove("d-none");
                    this.errorTarget.classList.remove("d-none");
                    document.querySelectorAll(".display-configurator-start").forEach(element => {
                        element.classList.remove("d-none");
                    });
                    document.querySelectorAll(".display-configurator-result").forEach(element => {
                        element.classList.add("d-none");
                    });
                    window.scrollTo(0, 50);


                }
            })
            .catch(error => {
                console.error("Fehler:", error);
                this.setFormState(this.formTarget,true);
                this.errorTarget.innerHTML = "Ein Netzwerkfehler ist aufgetreten.";

                this.formTarget.classList.remove("d-none");
                this.errorTarget.classList.remove("d-none");

                document.querySelectorAll(".display-configurator-start").forEach(element => {
                    element.classList.remove("d-none");
                });
                document.querySelectorAll(".display-configurator-result").forEach(element => {
                    element.classList.add("d-none");
                });
            });
    }

    setFormState(formTarget, state) {


        const buttons = formTarget.querySelectorAll("button");
        const inputs = formTarget.querySelectorAll("input, select, textarea");

        buttons.forEach(button => {
            button.disabled = !state;
        });

        inputs.forEach(input => {
            input.disabled = !state;
        });
        
    }


    configureUi(data)
    {

        if (data.temperature !== undefined) {
            this.temperatureTarget.value = data.temperature;
        }
        if (data.vds !== undefined) {

            this.vdsTarget.checked = data.vds == 1;
        }
        if (data.remote !== undefined) {
            this.remoteheadTarget.checked = data.remote == 1;
        }

        if (data.racks)
        {
            data.racks.forEach((rack, index) => {
                this.addRack(rack);
            })
        }

        this.loaderTarget.classList.add("d-none");
    }
    loadRequest(identifier,showResult=true) {

        console.log("Load with identifier " + identifier);

        let data= { identifier: identifier};

        //Load request, build frontend, get result:
        fetch(this.endpointValue + "LoadRequest", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(responseJson => {

                this.lastRequestIdentifier=identifier;
                this.configureUi(JSON.parse(responseJson.result));

                if (showResult) {

                    this.showResult(responseJson);
                }
                else
                {
                    this.loaderTarget.classList.add("d-none");
                    this.formTarget.classList.remove("d-none");
                }


            });
    }
}
