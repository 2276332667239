import { Controller } from '@hotwired/stimulus';
import {useIntersection} from "stimulus-use";


export default class extends Controller {

    static targets = [ "entry","input" ]

    connect() {

        this.element[this.identifier] = this

        if (this.entryTarget.dataset.values != undefined)
        {
            try {
                var data = JSON.parse(this.entryTarget.dataset.values);



                this.inputTargets.forEach((obj) => {


                    let inputname = obj.dataset.inputname;
                    let d = data[inputname];
                    obj.setAttribute("data-crudinput-data-value", d);

                    //obj.crudinput.test();
                    //console.log(obj.crudinput);

                });
                //Einzelnen Inputs die Daten geben:
            } catch (e) {

            }
        }
    }

    disconnect() {
        console.log("disconnect item");
    }

    remove(event)
    {

        this.entryTarget.parentNode.removeChild(this.entryTarget);
        console.log(event.currentTarget);


    }



}