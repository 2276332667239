import { Controller } from '@hotwired/stimulus';
import {useRegistration} from '@web-auth/webauthn-helper';


const register = useRegistration({
    actionUrl: '/admin/fido2?ajax=1&action=register',
    optionsUrl: '/admin/fido2?ajax=1&action=options'
});

export default class extends Controller {

    static targets = [ "tokenName" ]
    static values = {
        username: String,
        displayname: String,
    }

    connect()
    {


    }

    register(event)
    {

        console.log("event");



            register({
                username: this.usernameValue,
                displayName: this.displaynameValue
            })
                .then((response) => console.log('Registration success'))
                .catch((error) => console.log(error))
            ;

    }

}



