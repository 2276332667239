// datalist_validation_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input", "datalist"]

    connect() {
        this.inputTarget.addEventListener('input', () => this.validate());
    }

    validate() {
        console.log("validate");
        const inputValue = this.inputTarget.value;
        const optionElements = Array.from(this.datalistTarget.options);

        // Extrahiere den Textinhalt von jedem Option-Element
        const validTexts = optionElements.map(option => option.textContent || option.value);


        if (!validTexts.includes(inputValue.trim())) {
            this.inputTarget.setCustomValidity("Bitte wähle einen gültigen Wert aus der Liste.");
        } else {
            this.inputTarget.setCustomValidity(""); // Setzt die Validierungsnachricht zurück, wenn der Wert gültig ist
        }
        this.inputTarget.reportValidity(); // Zeigt die Validierungsnachricht direkt nach der Eingabe an
    }
}
