import {Controller} from '@hotwired/stimulus';
import {useIntersection} from "stimulus-use";
import {faLocationDot} from '@fortawesome/pro-solid-svg-icons'
import {DefaultRenderer, MarkerClusterer, Renderer} from "@googlemaps/markerclusterer";


export default class extends Controller {


    static targets = [ "map"]
    static values = {
        apikey: String,
        maploaded: Boolean,
        mapId: String,
        layer: String,
        mapcenter: String,
        zoomlevel: Number,
        maptype: String,
        marker: { type: Array, default: []},
        options: { type: JSON, default: []},

    }

    connect() {


        useIntersection(this, this.options);



    }

    maploadedValueChanged()
    {
        if (this.maploadedValue == "1")
        {
            import('../tools/custum_label').then((module) => {
                const CustomLabel = module.CustomLabel;
                this.drawMap(CustomLabel);
            });

        }

    }

    drawMap(CustomLabel)
    {




        let google=window.google;

        const mapOptions = {
            zoom: this.zoomlevelValue ,
            center: this.getLatLng(this.mapcenterValue),
            //maxZoom: 20,
            controls: {
                panControl: true,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: false,

            }
        };


        if (this.hasMapIdValue)
        {
            mapOptions.mapId=this.mapIdValue;
        }
        else
        {
            mapOptions.mapTypeId=this.maptypeValue;
        }

        const map = new google.maps.Map(this.mapTarget,mapOptions);

        const infoWindow = new google.maps.InfoWindow();

        this.drawOptions(CustomLabel,google,map);

        if (this.hasMarkerValue) {

            if (this.markerValue.length > 0) {

                this.markerValue.forEach(marker => {
                    console.log("addmarker", marker);


                    var obj = {
                        position: this.getLatLng(marker.pinlatlng),
                        title: marker.pinlabel,
                        map: map,
                    };

                    var combinedObj={};
                    var extension = null;
                    if (typeof marker.pinExtension === "string" && marker.pinExtension.trim() !== "") {
                        try {
                            extension = JSON.parse(marker.pinExtension);
                        } catch (e) {

                            console.log("extension not an object", marker.pinExtension);
                            extension = null; // Sicherstellen, dass extension null ist, falls JSON.parse fehlschlägt
                        }
                    }
                    if (extension && typeof extension === 'object' && !Array.isArray(extension)) {
                        console.log("combine",extension);
                         combinedObj = {
                            ...obj,
                            ...extension
                        };
                    } else {
                        console.log("obj onlny");
                         combinedObj=obj;
                    }

                    console.log(combinedObj);
                    const gmarker = new google.maps.Marker(combinedObj);

                    gmarker.addListener("click", () => {
                        infoWindow.close();
                        infoWindow.setContent("<strong>" + marker.pinlabel + "</strong>");
                        infoWindow.open(gmarker.getMap(), gmarker);
                    });
                });

            }
        }


        if (this.hasLayerValue)
        {
/*
            map.data.setStyle({
                icon:
                    {
                        path: faLocationPin.icon[4],
                        fillColor: "#981b5b",
                        fillOpacity: 1,
                        anchor: new google.maps.Point(
                            faLocationPin.icon[0] / 2, // width
                            faLocationPin.icon[1] // height
                        ),
                        strokeWeight: 1,
                        strokeColor: "#ffffff",
                        scale: 0.05,
                    },
                fillColor: 'green'
            });
  */
            let markers;

            map.data.loadGeoJson(this.layerValue, null, function (features) {
                markers = features.map(function (feature) {
                    let marker= new google.maps.Marker({
                        position: feature.getGeometry().get(0),
                        icon:
                            {
                                path: faLocationDot.icon[4],
                                fillColor: "#c6c441",
                                fillOpacity: 1,
                                anchor: new google.maps.Point(
                                    faLocationDot.icon[0] / 2, // width
                                    faLocationDot.icon[1] // height
                                ),
                                strokeWeight: 1,
                                strokeColor: "#ffffff",
                                scale: 0.075,
                            },
                    });


                    return marker;
                });
                map.data.setStyle({visible: false});






            class CustomRenderer  {
                render({ count, position }, stats, map) {
                    console.log("renderer called", count, position);
                    const color =
                        count > Math.max(10, stats.clusters.markers.mean)
                            ? "#c6c441"
                            : "#c6c441";

// create svg url with fill color
                    const svg = window.btoa(`
<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
  <circle cx="120" cy="120" opacity=".7" r="70" />
  <circle cx="120" cy="120" opacity=".5" r="90" />
  <circle cx="120" cy="120" opacity=".3" r="110" />
  <circle cx="120" cy="120" opacity=".1" r="130" />
</svg>`);

// create marker using svg icon
                    return new google.maps.Marker({
                        position,
                        icon: {
                            url: `data:image/svg+xml;base64,${svg}`,
                            scaledSize: new google.maps.Size(45, 45),
                        },
                        label: {
                            text: String(count),
                            color: "rgba(255,255,255,0.9)",
                            fontSize: "12px",
                        },
                        // adjust zIndex to be above other markers
                        zIndex: 1000 + count,
                    });
                }

            }

            let renderer = new CustomRenderer();

                new MarkerClusterer({ map: map, markers: markers, renderer: renderer  });





            })

        }



    }

    getLatLng(stringcoords)
    {

        let l=stringcoords.split(",")

        let lat=parseFloat(l[0]);
        let lng=parseFloat(l[1]);
        return { lat: lat, lng: lng};
        //let latlng=new google.maps.LatLng(lat,lng);
        //return latlng;
    }

    async appear(entry) {

        this.initMaps();
    }

    initMaps()
    {

        const script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.src = 'https://maps.googleapis.com/maps/api/js?key='+this.apikeyValue+"&libraries=geometry&callback=setMapInit";
        script.onerror = window.rejectInitPromise;
        document.querySelector('head').appendChild(script);

    }


    drawOptions(CustomLabel,google,map) {
        if (this.hasOptionsValue)
        {
            var options=this.optionsValue;

            if (options.polylines)
            {
                this.drawPolylines(CustomLabel,google,map,options.polylines);
            }
        }
    }


    drawPolylines(CustomLabel,google,map,lines) {


        for (var i = 0; i < lines.length; i++) {
            var line = lines[i];
            var latLng = new google.maps.LatLng(line.labelposition.lat, line.labelposition.lng);
            var decodedPath = google.maps.geometry.encoding.decodePath(line.line);
            var setRegion = new google.maps.Polyline({
                path: decodedPath,
                strokeColor: line.color,
                strokeOpacity: 1.0,
                strokeWeight: 2,
                map: map
            });

            var textOverlay = new CustomLabel(latLng, line.label, line.color);
            textOverlay.setMap(map);
        }
    }



}