import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

    static targets = ['content','switch'];
    static values = { invert: {  type: Boolean, default: false } }



    connect()
    {
        console.log("connect");

        setTimeout(() => {
            this.switch();
        }, 3000); // 3000 Millisekunden = 3 Sekunden
    }

    switch()
    {

        var state=this.switchTarget.checked;

        if (this.invertValue)
        {
            state=!state;
        }

        if (state)
        {
            this.contentTarget.classList.remove('d-none');
            this.setInputState(true);
        }
        else
        {
            this.contentTarget.classList.add('d-none');
            this.setInputState(false);
        }



    }
    setInputState(disabled) {
        const inputs = this.contentTarget.querySelectorAll('input, select, textarea, button');
        inputs.forEach(input => {
            input.disabled = !disabled;
        });
    }
}