import { Controller } from '@hotwired/stimulus';



export default class extends Controller {

    static values = { data: String, type: String }
    static targets = [ "input" ]

    connect() {

        this.element[this.identifier] = this

        this.setValues();
    }

    setValues()
    {


        if ((this.dataValue !== null) && (this.dataValue != "null"))
        {
            let type = (this.typeValue);

            if ((type == "textarea") || (type == "input" )|| (type == "text" )|| (type == "color" ) ||  (type == "select" )) {
                this.inputTarget.value = this.dataValue;

            }


            this.dataValue = null;
        }
    }

}