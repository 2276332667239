// scroll_controller.js
import { Controller } from '@hotwired/stimulus';
import ScrollMagic from 'scrollmagic';
import { gsap } from 'gsap';

export default class extends Controller {
    static targets = ['slide', 'imageContainer'];

    connect() {
        const controller = new ScrollMagic.Controller();
        const totalSlides = this.slideTargets.length;

        // Parallax-Bewegung nach unten
        const downTween = gsap.to(this.imageContainerTargets, {
            y: '150px', // Bewegt die Bildcontainer langsam nach unten 150
            ease: "none",
            paused: true // Steuerung über ScrollMagic
        });

        // Parallax-Bewegung zurück nach oben beim letzten Slide
        const upTween = gsap.to(this.imageContainerTargets, {
            y: '0px', // Setzt die Bildcontainer wieder nach oben
            ease: "none",
            paused: true // Steuerung über ScrollMagic
        });

        // Szene für die Parallax-Animation nach unten (über alle Slides bis zur Hälfte)
        new ScrollMagic.Scene({
            triggerElement: this.slideTargets[0],
            triggerHook: 0,
            duration: `${(totalSlides - 3) * 100}%`
        })
            .on("progress", (event) => {
                downTween.progress(event.progress);
            })
            .addTo(controller);

        // Szene für die Parallax-Animation zurück nach oben (nur im letzten Slide)
        new ScrollMagic.Scene({
            triggerElement: this.slideTargets[totalSlides - 2],
            triggerHook: 2,
            duration: "100%"
        })
            .on("progress", (event) => {
                upTween.progress(event.progress);
            })
            .addTo(controller);

        // Szene für jeden Slide zur Steuerung der Bildcontainer-Anzeige
        this.slideTargets.forEach((slide, index) => {

            const fadeIn = gsap.fromTo(
                slide,
                { opacity: 0, y: 50 },
                { opacity: 1, y: 0, duration: 0.3 }
            );

            new ScrollMagic.Scene({
                triggerElement: slide,
                triggerHook: 0.8,
                reverse: true
            })
                .on("enter", () => {
                    fadeIn.play();
                    this.showImageContainer(index);
                })
                .on("leave", () => {
                    this.hideImageContainer(index);
                })
                .addTo(controller);
        });
    }

    showImageContainer(index) {
        // Sanftes Einblenden des aktiven Bildcontainers
        this.imageContainerTargets.forEach((container, i) => {
            if (i === index) {
                gsap.to(container, { opacity: 1, duration: 0.1, ease: "power2.out" });
            }
        });
    }

    hideImageContainer(index) {
        // Sanftes Ausblenden des aktiven Bildcontainers beim Verlassen des Slides
        this.imageContainerTargets.forEach((container, i) => {
            if (i === index) {
                gsap.to(container, { opacity: 0, duration: 0.1, ease: "power2.in" });
            }
        });
    }
}
