import { Controller } from '@hotwired/stimulus';
import sortable from "html5sortable/dist/html5sortable.es";

export default class extends Controller {

    static targets = [ "list","skeleton","item" ]
    static values = {
        json: String
    }



    connect() {
        //Fill With Data

        this.setupFields();

        this.initSortable();
        this.element[this.identifier] = this
    }

    setupFields()
    {

        let list=JSON.parse(this.jsonValue);


        if (list!= null) {
            list.forEach((obj) => {
                this.addElement(null, obj);
            });
        }
    }

    initSortable()
    {
        sortable(this.listTarget,{ handle: ".moveCrudItem",
            placeholderClass: 'w-100 m-3 border-1 p-5 bg-primary'});
    }

    addElement(event,data) {
        this.listTarget.insertAdjacentHTML("beforeend",this.skeletonTarget.innerHTML);
        this.listTarget.lastElementChild.setAttribute("is-skeleton","0");

        this.listTarget.lastElementChild.dataset.values=JSON.stringify(data);

        //Daten setzen:

        this.initSortable();

    }

    getData() {
        var returnObj=[];
        this.itemTargets.forEach((obj)=> {

            if (obj.getAttribute("is-skeleton") == "0") {

                returnObj.push(convertFormToJSON(obj))
            }
            }
        )
        return returnObj;
    }


}