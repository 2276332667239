import { Controller } from '@hotwired/stimulus';
import { useIntersection } from "stimulus-use";
import $ from "jquery";


export default class extends Controller {


    static targets = [ "editor","input" ]
    static values = {
        mode: String,
    }

    editorId=null;
    editor=null;


    connect() {


        console.log("connect");

        console.log(this.modeValue);


        this.initAce();



    }

    saveValue(event)
    {
        this.inputTarget.value=this.editor.getValue();
    }

    initAce()
    {
        ace.require("ace/ext/language_tools");
        this.editor = ace.edit(this.editorTarget);
        this.editor.setTheme("ace/theme/chrome");
        this.editor.setOptions({
            autoScrollEditorIntoView: true,
            copyWithEmptySelection: true,
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true
        });

        this.editor.session.setMode("ace/mode/" + this.modeValue);
        let value=this.inputTarget.value;
        this.editor.setValue(value, -1);
    }

    disconnect() {
        super.disconnect();
        this.editor.destroy();
    }


}