import { Controller } from '@hotwired/stimulus';

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
export default class extends Controller {

    static targets = [ "image" ]
    static values = { galery: String, default: "galery" }


    connect() {

        console.log("bind fancy");
        this.imageTargets.forEach(target => {
            target.dataset.fancybox=this.galeryValue;
        });



        Fancybox.bind(this.element, "[data-fancybox]"), {        };

    }

    disconnect() {
        super.disconnect();

    }

}