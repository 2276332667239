import { Controller } from '@hotwired/stimulus';
import { useIntersection } from "stimulus-use";

export default class extends Controller {


    static values = {
        url: String,
    }

    appeared=false;

    connect() {
        useIntersection(this, this.options)

    }

    async appear(entry) {
        if (!this.appeared) {
            this.element.classList.add(this.element.dataset.appear);
        }
    }

    async disappear(entry)
    {
        this.element.classList.remove(this.element.dataset.disappear);
    }
}