// src/controllers/ticket_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["unlimitedCheckbox", "expireDate", "expiresInDays", "created"]

    connect() {
        this.assignedDate = new Date(this.createdTarget.value)

        this.updateFieldsState()

        this.unlimitedCheckboxTarget.addEventListener('change', () => this.updateFieldsState())
        this.expireDateTarget.addEventListener('input', () => this.updateExpiresInDays())
        this.expiresInDaysTarget.addEventListener('input', () => this.updateExpireDate())
    }

    updateFieldsState() {
        if (this.unlimitedCheckboxTarget.checked) {
            this.expireDateTarget.disabled = true
            this.expiresInDaysTarget.disabled = true
        } else {
            this.expireDateTarget.disabled = false
            this.expiresInDaysTarget.disabled = false
        }
    }

    updateExpiresInDays() {
        const expireDate = new Date(this.expireDateTarget.value)
        const diffTime = Math.abs(expireDate - this.assignedDate)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        this.expiresInDaysTarget.value = diffDays
    }

    updateExpireDate() {
        const days = parseInt(this.expiresInDaysTarget.value, 10)
        if (!isNaN(days)) {
            const expireDate = new Date(this.assignedDate)
            expireDate.setDate(expireDate.getDate() + days)
            this.expireDateTarget.value = expireDate.toISOString().slice(0, 16)
        }
    }
}
